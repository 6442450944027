export interface BulkDesignRequestStatus {
  id: number;
  name: string;
}

export enum BulkDesignRequestStatusId {
  PENDING = 1,
  AWAITING_CONCEPT,
  AWAITING_DESIGN,
  DESIGN,
  AWAITING_APPROVAL,
  FEEDBACK,
  REVISION_REQUESTED,
  REVISION,
  REVISION_FEEDBACK,
  APPROVED,
  AWAITING_SEND_TO_CUSTOMER,
  GHOST = 999
}

export const AvailableStatuses: BulkDesignRequestStatus[] = [
  {
    id: BulkDesignRequestStatusId.PENDING,
    name: 'Pending'
  },
  {
    id: BulkDesignRequestStatusId.AWAITING_CONCEPT,
    name: 'Awaiting Bulk Concept'
  },
  {
    id: BulkDesignRequestStatusId.AWAITING_DESIGN,
    name: 'Awaiting Bulk Design'
  },
  {
    id: BulkDesignRequestStatusId.DESIGN,
    name: 'In Design'
  },
  {
    id: BulkDesignRequestStatusId.AWAITING_APPROVAL,
    name: 'Awaiting Bulk Approval'
  },
  {
    id: BulkDesignRequestStatusId.FEEDBACK,
    name: 'Waiting For Feedback'
  },
  {
    id: BulkDesignRequestStatusId.REVISION_REQUESTED,
    name: 'Revision Requested'
  },
  {
    id: BulkDesignRequestStatusId.REVISION,
    name: 'In Revision'
  },
  {
    id: BulkDesignRequestStatusId.REVISION_FEEDBACK,
    name: 'Waiting for Feedback'
  },
  {
    id: BulkDesignRequestStatusId.APPROVED,
    name: 'Approved'
  },
  {
    id: BulkDesignRequestStatusId.AWAITING_SEND_TO_CUSTOMER,
    name: 'Awaiting Send to Customer'
  },
  {
    id: BulkDesignRequestStatusId.GHOST,
    name: 'Ghost'
  }
];

export function getBulkDesignRequestStatusById(id: BulkDesignRequestStatusId): BulkDesignRequestStatus {
  return AvailableStatuses.find((s) => s.id === id);
}

export function bulkDesignRequestStatusName(id: BulkDesignRequestStatusId): string {
  const name = getBulkDesignRequestStatusById(id).name;
  return name;
}
