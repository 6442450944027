import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject, Observable } from 'rxjs';
import { OrderProductPhoto } from '../models/order-product-photo.model';
import { OrderProduct } from '../models/order-product.model';
import { SocketService } from './socket.service';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class OrderProductPhotosService {
  private service: any;
  private socket: any;
  private orderProductPhotoUploadedSubject: Subject<OrderProductPhoto> = new Subject();
  private orderProductPhotoUpdatedSubject: Subject<OrderProductPhoto> = new Subject();

  constructor(private api: ApiService, private socketService: SocketService) {
    this.service = this.api.service('order-product-photos');
    this.socket = this.socketService.service('order-product-photos');

    this.socket.on('created', (orderProductPhoto: OrderProductPhoto) => {
      this.orderProductPhotoUploadedSubject.next(orderProductPhoto);
    });

    this.socket.on('updated', (orderProductPhoto: OrderProductPhoto) => {
      this.orderProductPhotoUpdatedSubject.next(orderProductPhoto);
    });

    this.socket.on('patched', (orderProductPhoto: OrderProductPhoto) => {
      this.orderProductPhotoUpdatedSubject.next(orderProductPhoto);
    });
  }

  public create(data?: any): Promise<any> {
    return this.service.create(data);
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public patch(id: number, data: any): Promise<any> {
    return this.service.patch(id, data);
  }

  public onOrderProductPhotoUploaded(): Observable<{}> {
    return this.orderProductPhotoUploadedSubject.asObservable();
  }

  public onOrderProductPhotoUpdated(): Observable<{}> {
    return this.orderProductPhotoUpdatedSubject.asObservable();
  }

  /**
   * Download file directly from S3.
   */
  public async download(photo: OrderProductPhoto): Promise<any> {
    const response: Response = await fetch(photo.url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Origin: window.location.origin
      }
    });
    FileSaver.saveAs(await response.blob(), photo.filename.replace('from_admin/', ''));
  }

  /**
   * Download all original photos for given OP
   */
  public async downloadForOrderProduct(orderProduct: OrderProduct): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const zip = new JSZip();

      try {
        for (const photo of orderProduct.orderProductPhotos) {
          if (photo.active && !photo.badPhotoReason) {
            const response: Response = await fetch(photo.url, {
              method: 'GET',
              mode: 'cors',
              cache: 'no-cache',
              headers: {
                Origin: window.location.origin
              }
            });

            if (response.ok) {
              zip.file(orderProduct.number + '_' + photo.filename.replace('from_admin/', ''), response.blob(), {
                binary: true
              });
            } else {
              throw Error(
                'Error for ' +
                  photo.filename +
                  ': ' +
                  (response.statusText ? response.statusText : response.status.toString())
              );
            }
          }
        }
      } catch (err) {
        reject(err);
        return;
      }

      zip.generateAsync({ type: 'blob' }).then(
        (blob: Blob) => {
          FileSaver.saveAs(blob, 'photos-' + orderProduct.number + '.zip');
          resolve('');
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
