import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject, Observable } from 'rxjs';
import { SocketService } from './socket.service';
import { OrderActionLogItem } from '../models/order-action-log-item.model';

@Injectable({
  providedIn: 'root'
})
export class OrderActionLogService {
  private service: any;
  private socket: any;
  private actionLogSubject: Subject<OrderActionLogItem> = new Subject();

  constructor(private api: ApiService, private socketService: SocketService) {
    this.service = this.api.service('order-action-log');
    this.socket = this.socketService.service('order-action-log');

    this.socket.on('created', (actionLogItem: OrderActionLogItem) => {
      this.actionLogSubject.next(actionLogItem);
    });
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public create(data: any): Promise<any> {
    return this.service.create(data);
  }

  public onActionLogItemCreated(): Observable<{}> {
    return this.actionLogSubject.asObservable();
  }
}
