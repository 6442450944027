import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { RoleId } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private permissionsService: PermissionsService, private router: Router) {}

  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      await this.permissionsService.authenticate();
      const roles = next.data['roles'] as Array<RoleId>;
      const skipAdmin = next.data['skipAdmin'];
      const allowOuthouseManagers = next.data.allowOuthouseManagers;

      if (!roles || this.permissionsService.restrictToRoles(roles, !skipAdmin) || allowOuthouseManagers) {
        return true;
      } else {
        await this.router.navigate(['/']);
        return false;
      }
    } catch (err) {
      this.permissionsService.loginRedirectUrl = state.url !== '/' ? state.url : null;
      await this.permissionsService.logout();
      return false;
    }
  }
}
