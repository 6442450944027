<app-loader *ngIf="loading"></app-loader>

<h2 mat-dialog-title>Report Issue</h2>
<mat-dialog-content>
  <form class="reporting-form" #reportingForm="ngForm" (submit)="onSubmit()">
    <img [src]="data.image" alt="" />

    <p>
      <mat-radio-group [(ngModel)]="type" name="type" required aria-label="Select type of report">
        <mat-radio-button value="bug">Bug/technical issue</mat-radio-button>
        <mat-radio-button value="person">Abuse/bad behaviour</mat-radio-button>
      </mat-radio-group>
    </p>

    <p *ngIf="type === 'person'">
      <mat-form-field appearance="outline">
        <mat-label>Name of the person</mat-label>
        <input type="text" [(ngModel)]="reportee" name="reportee" matInput required />
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Describe the problem</mat-label>
        <textarea [(ngModel)]="description" name="description" matInput required></textarea>
      </mat-form-field>
    </p>

    <div class="actions">
      <button type="button" (click)="close()" mat-stroked-button>
        <mat-icon>close</mat-icon>
        Close
      </button>

      <button type="submit" mat-raised-button color="accent">Send</button>
    </div>
  </form>
</mat-dialog-content>
