import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxMdService } from 'ngx-md';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private successEmojis: string[] = [
    '😊',
    '👍',
    '😎',
    '👌',
    '😉',
    '🤗',
    '😻',
    '😍',
    '😁',
    '😃',
    '🙂',
    '🤩',
    '😊',
    '😝',
    '✅',
    '🥹',
    '👏',
    '🥰',
    '🎉',
    '🥳',
    '🙌',
    '💪',
    '🌟',
    '🏆',
    '🎊',
    '🎈',
    '✨',
    '😇',
    '😌',
    '😺',
    '😸',
    '😻'
  ];
  private errorEmojies: string[] = [
    '😕',
    '😮',
    '😫',
    '😒',
    '😟',
    '😞',
    '😖',
    '😧',
    '😵',
    '😨',
    '🚨',
    '😔',
    '😭',
    '😿',
    '🥲',
    '😢',
    '💔',
    '☹️',
    '🥺',
    '❌',
    '😣',
    '😩',
    '😓',
    '😥',
    '😰',
    '😱',
    '😳',
    '😵‍💫',
    '🤯',
    '🫨'
  ];

  constructor(private toastrService: ToastrService, private ngxMdService: NgxMdService) {}

  public error(err: Error | string | any, heading: string = null, options: any = {}): void {
    const defaultOptions = {
      positionClass: 'toast-top-right',
      enableHtml: true,
      closeButton: true,
      disableTimeOut: environment.production
    };
    options = { ...defaultOptions, ...options };

    const emojiIndex = this.randomInt(0, this.errorEmojies.length - 1);
    let message;
    if (err.response && err.response.body && err.response.body.message) {
      message = err.response.body.message;

      if (err.response.body.name) {
        heading = err.response.body.name;
      }
    } else if (err.message) {
      message = err.message;
    } else if (typeof err === 'string') {
      message = err;
    } else {
      message = 'An error occurred';
    }

    if (err.errors && err.errors.length > 0) {
      message += ': ' + err.errors[0].message;
    }

    message += ' ' + this.errorEmojies[emojiIndex];

    this.toastrService.error(
      this.ngxMdService.compile('<div class="markdown-body wntr-toast keep-color">' + message + '</div>'),
      heading,
      options
    );
  }

  public success(message: string = 'Success', heading: string = null, options: any = {}): void {
    const defaultOptions = {
      timeOut: 5000,
      positionClass: 'toast-top-right',
      enableHtml: true
    };
    options = { ...defaultOptions, ...options };

    const emojiIndex = this.randomInt(0, this.successEmojis.length - 1);

    if (message) {
      message += ' ' + this.successEmojis[emojiIndex];
    } else {
      message = 'Success ' + this.successEmojis[emojiIndex];
    }

    this.toastrService.success(
      this.ngxMdService.compile('<div class="markdown-body wntr-toast keep-color">' + message + '</div>'),
      heading,
      options
    );
  }

  public info(
    message: string,
    heading: string = null,
    options: any = {
      timeOut: 5000,
      positionClass: 'toast-top-right',
      enableHtml: true
    }
  ): void {
    this.toastrService.info(
      this.ngxMdService.compile('<div class="markdown-body wntr-toast keep-color">' + message + '</div>'),
      heading,
      options
    );
  }

  public warn(
    message: string,
    heading: string = null,
    options: any = {
      timeOut: 5000,
      positionClass: 'toast-top-right',
      enableHtml: true
    }
  ): void {
    this.toastrService.warning(
      this.ngxMdService.compile('<div class="markdown-body wntr-toast keep-color">' + message + '</div>'),
      heading,
      options
    );
  }

  /**
   * Generate a random integer between min and max
   * @param {number} min
   * @param {number} max
   * @return {number} random generated integer
   */
  public randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
