import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SocketService } from './socket.service';
import { Subject, Observable } from 'rxjs';
import { TransitionLogItem } from '../models/transition-log.model';

@Injectable({
  providedIn: 'root'
})
export class TransitionLogService {
  private service: any;
  private socket: any;
  private transitionLogSubject: Subject<TransitionLogItem> = new Subject();

  constructor(private api: ApiService, private socketService: SocketService) {
    this.service = this.api.service('transition-log');
    this.socket = this.socketService.service('transition-log');

    this.socket.on('created', (transitionLogItem: TransitionLogItem) => {
      this.transitionLogSubject.next(transitionLogItem);
    });
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public onTransitionLogItemCreated(): Observable<{}> {
    return this.transitionLogSubject.asObservable();
  }
}
