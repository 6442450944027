import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerServiceService {
  private service: any;

  constructor(private api: ApiService) {
    this.service = this.api.service('customer-service');
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public getCounts(): Promise<any> {
    return this.find({ query: { request: 'counts' } });
  }

  public getFor(request: string, params: any = { query: {} }): Promise<any> {
    params.query.request = request;
    return this.find(params);
  }

  public getTodo(params: any = { query: {} }): Promise<any> {
    params.query.request = 'todo';
    return this.find(params);
  }

  public getCooldown(params: any = { query: {} }): Promise<any> {
    params.query.request = 'cooldown';
    return this.find(params);
  }

  public getUnassigned(params: any = { query: {} }): Promise<any> {
    params.query.request = 'unassigned';
    return this.find(params);
  }

  public getOthers(params: any = { query: {} }): Promise<any> {
    params.query.request = 'others';
    return this.find(params);
  }
}
