import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private service: any;

  constructor(private api: ApiService) {
    this.service = this.api.service('reports');
  }

  public create(data: any): Promise<any> {
    return this.service.create(data);
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public get(id: number, params?: any): Promise<any> {
    return this.service.get(id, params);
  }

  public patch(id: number, data: any): Promise<any> {
    return this.service.patch(id, data);
  }

  public remove(id: number): Promise<any> {
    return this.service.remove(id);
  }
}
