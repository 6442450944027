export interface Status {
  id: number;
  name: string;
  shortName?: string;
}

export enum StatusId {
  NEEDS_NOTE_REVIEW = 1,
  AWAITING_SIMPLE_CROP,
  IN_SIMPLE_CROP,
  AWAITING_AUTO_CROP,
  AWAITING_DESIGN,
  IN_DESIGN,
  BAD_PHOTO,
  AWAITING_DESIGN_REVIEW,
  IN_REVIEW,
  DESIGN_REJECTED,
  AWAITING_TEMPLATE,
  SCATTERING,
  AWAITING_SAMPLE_PRINT,
  IN_SAMPLE_PRODUCTION,
  AWAITING_SAMPLE_APPROVAL,
  SAMPLE_REJECTED,
  AWAITING_PRINT,
  IN_PRODUCTION,
  PARTIALLY_SHELVED,
  REDESIGN,
  REPRINT,
  SHELVED,
  AWAITING_SHIPMENT,
  SHIPPED,
  CANCELED,
  SHIPMENT_ON_HOLD,
  REFUNDED
}

export const BeforePrintStatuses: StatusId[] = [
  StatusId.NEEDS_NOTE_REVIEW,
  StatusId.AWAITING_SIMPLE_CROP,
  StatusId.IN_SIMPLE_CROP,
  StatusId.AWAITING_AUTO_CROP,
  StatusId.AWAITING_DESIGN,
  StatusId.IN_DESIGN,
  StatusId.BAD_PHOTO,
  StatusId.AWAITING_DESIGN_REVIEW,
  StatusId.IN_REVIEW,
  StatusId.DESIGN_REJECTED,
  StatusId.AWAITING_TEMPLATE,
  StatusId.SCATTERING,
  StatusId.AWAITING_PRINT,
  StatusId.REDESIGN,
  StatusId.REPRINT,
  StatusId.AWAITING_SAMPLE_PRINT
];

export const AvailableStatuses: Status[] = [
  {
    id: StatusId.NEEDS_NOTE_REVIEW,
    name: 'Needs note review',
    shortName: 'Note Review'
  },
  {
    id: StatusId.AWAITING_SIMPLE_CROP,
    name: 'Awaiting simple crop'
  },
  {
    id: StatusId.IN_SIMPLE_CROP,
    name: 'In simple crop'
  },
  {
    id: StatusId.AWAITING_AUTO_CROP,
    name: 'Awaiting auto crop'
  },
  {
    id: StatusId.AWAITING_DESIGN,
    name: 'Awaiting design'
  },
  {
    id: StatusId.IN_DESIGN,
    name: 'In design'
  },
  {
    id: StatusId.BAD_PHOTO,
    name: 'Bad photo'
  },
  {
    id: StatusId.AWAITING_DESIGN_REVIEW,
    name: 'Awaiting design review',
    shortName: 'WF Review'
  },
  {
    id: StatusId.IN_REVIEW,
    name: 'In review',
    shortName: 'Review'
  },
  {
    id: StatusId.DESIGN_REJECTED,
    name: 'Design rejected',
    shortName: 'Rejected'
  },
  {
    id: StatusId.AWAITING_TEMPLATE,
    name: 'Awaiting template',
    shortName: 'WF Tmplt'
  },
  {
    id: StatusId.SCATTERING,
    name: 'Scattering',
    shortName: 'Scat'
  },
  {
    id: StatusId.AWAITING_SAMPLE_PRINT,
    name: 'Awaiting Sample Print'
  },
  {
    id: StatusId.IN_SAMPLE_PRODUCTION,
    name: 'In Sample Production'
  },
  {
    id: StatusId.AWAITING_SAMPLE_APPROVAL,
    name: 'Awaiting Sample Approval'
  },
  {
    id: StatusId.SAMPLE_REJECTED,
    name: 'Sample Rejected'
  },
  {
    id: StatusId.AWAITING_PRINT,
    name: 'Awaiting print',
    shortName: 'WF Print'
  },
  {
    id: StatusId.IN_PRODUCTION,
    name: 'In production',
    shortName: 'Print'
  },
  {
    id: StatusId.PARTIALLY_SHELVED,
    name: 'Partially shelved'
  },
  {
    id: StatusId.REDESIGN,
    name: 'Redesign'
  },
  {
    id: StatusId.REPRINT,
    name: 'Reprint'
  },
  {
    id: StatusId.SHELVED,
    name: 'Shelved',
    shortName: 'Shelf'
  },
  {
    id: StatusId.AWAITING_SHIPMENT,
    name: 'Awaiting shipment',
    shortName: 'WF Ship'
  },
  {
    id: StatusId.SHIPPED,
    name: 'Shipped'
  },
  {
    id: StatusId.CANCELED,
    name: 'Canceled'
  },
  {
    id: StatusId.SHIPMENT_ON_HOLD,
    name: 'Shipment on hold',
    shortName: 'On hold'
  },
  {
    id: StatusId.REFUNDED,
    name: 'Refunded'
  }
];

export function getStatusById(id: StatusId): Status {
  return AvailableStatuses.find((s) => s.id === id);
}

export function statusName(id: StatusId): string {
  const name = getStatusById(id).name;
  return name;
}
