import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { RoleId } from '../models/role.model';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';
import { ToastService } from './toast.service';
import { Team } from '../models/team.model';
import { Router } from '@angular/router';
import { PermissionsService } from './permissions.service';
import * as JwtDecode from 'jwt-decode';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private service: any;

  constructor(
    private api: ApiService,
    private permissionsService: PermissionsService,
    private toastService: ToastService,
    private cache: CacheService,
    private router: Router
  ) {
    this.service = this.api.service('users');
  }

  public create(data: any): Promise<any> {
    return this.service.create(data);
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public get(id: number, params?: any): Promise<any> {
    return this.service.get(id, params);
  }

  public patch(id: number, data: any): Promise<any> {
    return this.service.patch(id, data);
  }

  public remove(id: number): Promise<any> {
    return this.service.remove(id);
  }

  public async findCached(teamId: number = null): Promise<any> {
    const existingData = this.cache.get('users' + teamId ? '_team_' + teamId : '');
    if (existingData) {
      return existingData;
    } else {
      try {
        const query: any = { $limit: -1 };

        if (teamId) {
          query['$teams.id$'] = teamId;
        }

        const usersResponse = await this.find({ query: query });

        // Only show users with roles other than superadmin and system in the filters
        const users = usersResponse.filter((user: User) => {
          return Math.max(...user.roleIds) > RoleId.SUPERADMIN && Math.max(...user.roleIds) !== RoleId.SYSTEM;
        });
        // tslint:disable-next-line:max-line-length
        this.cache.set('users' + teamId ? '_team_' + teamId : '', users, environment.cache.ttl.usersList);

        return users;
      } catch (err) {
        this.toastService.error(err);
        return [];
      }
    }
  }

  public async isUserInTeams(teamIds: number[], userId: number): Promise<boolean> {
    for (const teamId of teamIds) {
      const users = await this.findCached(teamId);
      const user = users.find((u) => u.id === userId);
      if (user && user.teamIds.includes(teamId)) {
        return true;
      }
    }

    return false;
  }

  public isManagerIn(teamId: number, managerIn: Team[]): boolean {
    const managerInIds = managerIn.map((team) => team.id);
    return managerInIds.includes(teamId);
  }

  public async changeLocation(locationId: number): Promise<void> {
    try {
      await this.patch(this.permissionsService.user.id, {
        locationId: locationId
      });

      const response = await this.api.authenticate();
      this.permissionsService.setUser(JwtDecode(response.accessToken));
    } catch (err) {
      throw err;
    }
  }
}
