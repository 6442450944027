export interface WaitingForCustomerReason {
  id: number;
  name: string;
}

export enum WaitingForCustomerReasonId {
  CLEAR = 0,
  UNCLEAR_NOTE,
  UNCLEAR_SUBJECT,
  BAD_PHOTO,
  INVALID_ADDRESS,
  INVOICED,
  EXPEDITED_REQUEST,
  OTHER,
  MAKE_MANUAL_SHIPPING_ENTRY,
  NEEDS_REFUND_APPROVAL,
  WRONG_TEMPLATE,
  CROP_TOGETHER,
  MANUAL_SCATTER,
  ADDRESS_BUTTON,
  DESIGN_QUESTION,
  FRONT
}

export const AvailableWaitingForCustomerReasons: WaitingForCustomerReason[] = [
  {
    id: WaitingForCustomerReasonId.UNCLEAR_NOTE,
    name: 'Needs note clarification'
  },
  {
    id: WaitingForCustomerReasonId.UNCLEAR_SUBJECT,
    name: 'Needs subject clarification'
  },
  {
    id: WaitingForCustomerReasonId.BAD_PHOTO,
    name: 'Needs new photos'
  },
  {
    id: WaitingForCustomerReasonId.INVALID_ADDRESS,
    name: 'Needs new address'
  },
  {
    id: WaitingForCustomerReasonId.INVOICED,
    name: 'Needs invoice payment'
  },
  {
    id: WaitingForCustomerReasonId.EXPEDITED_REQUEST,
    name: 'Expedited request'
  },
  {
    id: WaitingForCustomerReasonId.OTHER,
    name: 'Other'
  },
  {
    id: WaitingForCustomerReasonId.MAKE_MANUAL_SHIPPING_ENTRY,
    name: 'Make SE manual entry'
  },
  {
    id: WaitingForCustomerReasonId.NEEDS_REFUND_APPROVAL,
    name: 'Needs Refund Approval'
  },
  {
    id: WaitingForCustomerReasonId.WRONG_TEMPLATE,
    name: 'Wrong template'
  },
  {
    id: WaitingForCustomerReasonId.CROP_TOGETHER,
    name: 'Crop together'
  },
  {
    id: WaitingForCustomerReasonId.MANUAL_SCATTER,
    name: 'Manual Scatter'
  },
  {
    id: WaitingForCustomerReasonId.ADDRESS_BUTTON,
    name: 'Address Button'
  },
  {
    id: WaitingForCustomerReasonId.DESIGN_QUESTION,
    name: 'Design Question'
  },
  {
    id: WaitingForCustomerReasonId.FRONT,
    name: 'Front'
  }
];

export function getWfcReasonById(id: WaitingForCustomerReasonId): WaitingForCustomerReason {
  return AvailableWaitingForCustomerReasons.find((s) => s.id === id);
}

export function wfcReasonName(id: WaitingForCustomerReasonId): string {
  const name = getWfcReasonById(id) ? getWfcReasonById(id).name : 'Unknown';
  return name;
}
