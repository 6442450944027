import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject, Observable } from 'rxjs';
import { SocketService } from './socket.service';
import { Order } from '../models/order.model';
import { environment } from '../../environments/environment';
import { ToastService } from './toast.service';
import { CacheService } from './cache.service';
import { WaitingForCustomerReasonId } from '../models/waiting-for-customer-reason.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private service: any;
  private socket: any;
  private orderCreatedSubject: Subject<{}> = new Subject();
  private orderUpdatedSubject: Subject<{}> = new Subject();

  constructor(
    private api: ApiService,
    private socketService: SocketService,
    private readonly cache: CacheService,
    private toastService: ToastService
  ) {
    this.service = this.api.service('orders');
    this.socket = this.socketService.service('orders');

    this.socket.on('created', (order: Order) => {
      this.orderCreatedSubject.next(order);
    });

    this.socket.on('updated', (order: Order) => {
      this.orderUpdatedSubject.next(order);
    });

    this.socket.on('patched', (order: Order) => {
      this.orderUpdatedSubject.next(order);
    });
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public get(id: number | string, params?: any): Promise<any> {
    return this.service.get(id, params);
  }

  public patch(id: number, data: any, params: any = {}): Promise<any> {
    return this.service.patch(id, data, params);
  }

  public onOrderCreated(): Observable<{}> {
    return this.orderCreatedSubject.asObservable();
  }

  public onOrderUpdated(): Observable<{}> {
    return this.orderUpdatedSubject.asObservable();
  }

  public async getShippingMethods() {
    const existingData = this.cache.get('shipping-method-titles-distinct');

    if (existingData) {
      return existingData;
    }

    const data = (
      await this.api.service('order-shipping-lines').find({
        query: {
          $distinct: 'methodTitle',
          $limit: -1
        }
      })
    ).data.map((d) => {
      return {
        key: d.name,
        name: d.name
      };
    });

    data.sort((a, b) => a.name.localeCompare(b.name));

    this.cache.set('shipping-method-titles-distinct', data, environment.cache.ttl.ordersDistinct);

    return data;
  }

  public async getDistinct(): Promise<any> {
    let data = {
      currencies: [],
      shippingCountries: [],
      paymentMethods: [],
      sources: [],
      coupons: []
    };

    const existingData = this.cache.get('orders-distinct');

    if (existingData) {
      return existingData;
    } else {
      try {
        const responses = await Promise.all([
          this.find({
            query: {
              $distinct: 'currency'
            }
          }),
          this.find({
            query: {
              $distinct: 'shippingCountry'
            }
          }),
          this.find({
            query: {
              $distinct: 'paymentMethod'
            }
          }),
          this.find({
            query: {
              $distinct: 'source'
            }
          }),
          this.api.service('order-coupon-lines').find({
            query: {
              $distinct: 'code'
            }
          })
        ]);

        data = {
          currencies: responses[0].data.filter((currency) => currency && currency.name.length !== 0),
          shippingCountries: responses[1].data.filter(
            (shippingCountry) => shippingCountry && shippingCountry.name.length !== 0
          ),
          paymentMethods: responses[2].data.filter((paymentMethod) => paymentMethod && paymentMethod.name.length !== 0),
          sources: responses[3].data.filter((source) => source && source.name.length !== 0),
          coupons: responses[4].data.filter((coupon) => coupon && coupon.name.length !== 0)
        };

        this.cache.set('orders-distinct', data, environment.cache.ttl.ordersDistinct);
      } catch (err) {
        this.toastService.error(err);
      }

      return data;
    }
  }

  public async createReshipment(id: number, data: any): Promise<any> {
    return await this.api.superPost('orders/' + id + '/create-reshipment', data);
  }

  public markWaitingForCustomer(id: number, reasonId: WaitingForCustomerReasonId): Promise<any> {
    return this.markOrClearWaitingForCustomer(id, reasonId);
  }

  public clearWaitingForCustomer(id: number): Promise<any> {
    return this.markOrClearWaitingForCustomer(id);
  }

  public async markOrClearWaitingForCustomer(
    id: number,
    reasonId: WaitingForCustomerReasonId = WaitingForCustomerReasonId.CLEAR
  ): Promise<any> {
    return await this.api.superPut('orders/' + id + '/mark-waiting-for-customer', { reasonId: reasonId });
  }

  public async cancelOrder(id: number): Promise<any> {
    return await this.api.superPut('orders/' + id + '/mark-canceled', {
      cancel: true
    });
  }

  public async unCancelOrder(id: number): Promise<any> {
    return await this.api.superPut('orders/' + id + '/mark-canceled', {
      cancel: false
    });
  }

  public async markPaid(id: number): Promise<any> {
    return await this.api.superPut('orders/' + id + '/mark-paid');
  }

  public async markShipped(id: number, tracking?: any): Promise<any> {
    return await this.api.superPut('orders/' + id + '/mark-shipped', { manual: true, tracking });
  }

  public async resendOrderConfirmation(id: number): Promise<any> {
    return await this.api.superPut('orders/' + id + '/resend-order-confirmation');
  }

  public async letterShip(id: number): Promise<any> {
    return await this.api.superPut('orders/' + id + '/mark-shipped?skipHooks=true', { letterShip: true });
  }

  public async getShipEngineCalcData(id: number, params: any = {}) {
    return await this.api.service('ship-engine').get(id, params);
  }

  public async shipEngineShip(id: number, data = {}): Promise<any> {
    return await this.api.service('ship-engine').create({
      orderId: id,
      ...data
    });
  }

  public async shipEngineShipmentData(id: string): Promise<any> {
    return await this.api.service('ship-engine').create({
      shipment: id
    });
  }

  public async shipEngineVoidLabel(id: number): Promise<any> {
    return await this.api.service('ship-engine').remove(id);
  }

  public async deleteOrder(id: number): Promise<any> {
    return this.service.remove(id);
  }

  public async downloadBulkInvoice(id: number): Promise<any> {
    const response: Response = await fetch((await this.api.superGet('orders/' + id + '/get-bulk-invoice')).url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Origin: window.location.origin
      }
    });

    if (response.ok) {
      this.api.download(await response.text());
    } else {
      throw new Error(`Failed to download invoice, status ${response.status}`);
    }
  }
}
