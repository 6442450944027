import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class S3SigningsService {
  private service: any;

  constructor(private api: ApiService) {
    this.service = this.api.service('s3-signings');
  }

  public async find(directory: string, fileName: string, contentType?: any, b2b: boolean = false): Promise<any> {
    return await this.service.find({
      query: {
        directory,
        fileName,
        contentType,
        b2b
      }
    });
  }
}
