import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import * as hat from 'hat';
import { base64ToFile } from 'ngx-image-cropper';
import { ApiService } from 'src/app/services/api.service';
import { ReportsService } from 'src/app/services/reports.service';
import { S3SigningsService } from 'src/app/services/s3-signings.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-reporting-dialog',
  templateUrl: './reporting-dialog.component.html',
  styleUrls: ['./reporting-dialog.component.scss']
})
export class ReportingDialogComponent {
  public loading: boolean = false;
  public type: String = 'bug';
  public description: String;
  public reportee: String;

  @ViewChild('reportingForm') reportingForm: NgForm;

  constructor(
    public dialogRef: MatDialogRef<ReportingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { image: string; time: Date; dataDump: any },
    private reportsService: ReportsService,
    private s3SigningsService: S3SigningsService,
    private apiService: ApiService,
    private toastService: ToastService
  ) {}

  public async onSubmit(): Promise<void> {
    if (!this.reportingForm.valid) {
      return;
    }

    this.loading = true;
    try {
      const signData: any = await this.s3SigningsService.find(`reports`, hat() + '.png');
      await this.apiService.uploadSignedImageS3(signData, base64ToFile(this.data.image));

      await this.reportsService.create({
        type: this.type,
        description: this.description,
        url: window.location.href,
        userAgent: navigator.userAgent,
        screenshot: signData.fields.key,
        screenshotTime: this.data.time,
        reportee: this.reportee,
        dataDump: this.data.dataDump
      });

      this.toastService.success('Report saved, thank you!', null);

      this.close();
    } catch (err) {
      this.toastService.error('Failed to save report!', null);
      throw err;
    } finally {
      this.loading = false;
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
