import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Provider, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoaderModule } from './components/loader/loader.module';
import { RootModule } from './pages/root/root.module';
import { Angulartics2Module } from 'angulartics2';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ReportingDialogModule } from './components/dialogs/reporting-dialog/reporting-dialog.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

const providers: Provider[] = [];

export class ChunkLoadErrorHandler implements ErrorHandler {
  public handleError(err: any): void {
    if (err.message.includes('ChunkLoadError')) {
      window.location.reload();
    } else {
      console.error(err);
    }
  }
}

providers.push({ provide: ErrorHandler, useClass: ChunkLoadErrorHandler });

if (environment.sentry.active) {
  providers.push({
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false
    })
  });

  providers.push({
    provide: Sentry.TraceService,
    deps: [Router]
  });

  providers.push({
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true
  });
}

@NgModule({
  declarations: [AppComponent, SidebarComponent],
  imports: [
    RootModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BrowserTransferStateModule,
    LoaderModule,
    // Material
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    // Flex-layout
    FlexLayoutModule,
    // Routing
    AppRoutingModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearQueryParams: true,
        clearHash: true
      }
    }),
    ReportingDialogModule
  ],
  providers: providers,
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
