import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { WaitingForCustomerReasonId } from '../models/waiting-for-customer-reason.model';
import { StatusId } from '../models/status.model';
import { SocketService } from './socket.service';
import { Observable, Subject } from 'rxjs';
import { OrderProduct } from '../models/order-product.model';
import { environment } from '../../environments/environment';
import { ToastService } from './toast.service';
import { OrdersService } from './orders.service';
import { CacheService } from './cache.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class OrderProductsService {
  private service: any;
  private socket: any;
  private orderProductCreatedSubject: Subject<{}> = new Subject();
  private orderProductUpdatedSubject: Subject<{}> = new Subject();
  private orderProductRemovedSubject: Subject<{}> = new Subject();
  private queueUpdatedSubject: any = {};
  private queueForceLeave: any = {};
  private queueReceivedOrderProduct: any = {};

  constructor(
    private api: ApiService,
    private ordersService: OrdersService,
    private socketService: SocketService,
    private readonly cache: CacheService,
    private toastService: ToastService,
    private usersService: UsersService
  ) {
    this.service = this.api.service('order-products');
    this.socket = this.socketService.service('order-products');

    this.socket.on('updated', (orderProduct: OrderProduct) => {
      this.orderProductUpdatedSubject.next(orderProduct);
    });

    this.socket.on('patched', (orderProduct: OrderProduct) => {
      this.orderProductUpdatedSubject.next(orderProduct);
    });

    this.socket.on('removed', (orderProduct: OrderProduct) => {
      this.orderProductRemovedSubject.next(orderProduct);
    });

    for (const queueKey of environment.queues.map((q) => q.key)) {
      this.queueUpdatedSubject[queueKey] = new Subject();
      this.queueForceLeave[queueKey] = new Subject();
      this.queueReceivedOrderProduct[queueKey] = new Subject();

      this.socket.on(queueKey, (queue: number[]) => {
        this.queueUpdatedSubject[queueKey].next(queue);
      });

      this.socket.on(`${queueKey}-force-leave`, (queue: number[]) => {
        this.queueForceLeave[queueKey].next(queue);
      });

      this.socket.on(`${queueKey}-receive`, (orderProductId: number) => {
        this.queueReceivedOrderProduct[queueKey].next(orderProductId);
      });
    }
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public get(id: number, params?: any): Promise<any> {
    return this.service.get(id, params);
  }

  public patch(id: number, data: any, params?: any): Promise<any> {
    return this.service.patch(id, data, params ? params : {});
  }

  public create(data: any): Promise<any> {
    return this.service.create(data);
  }

  public onOrderProductCreated(): Observable<{}> {
    return this.orderProductCreatedSubject.asObservable();
  }

  public onOrderProductUpdated(): Observable<{}> {
    return this.orderProductUpdatedSubject.asObservable();
  }

  public onOrderProductRemoved(): Observable<{}> {
    return this.orderProductRemovedSubject.asObservable();
  }

  public onQueueUpdated(queueName: string): Observable<{}> {
    return this.queueUpdatedSubject[queueName].asObservable();
  }

  public onQueueForceLeave(queueName: string): Observable<{}> {
    return this.queueForceLeave[queueName].asObservable();
  }

  public onQueueReceivedOrderProduct(queueName: string): Observable<{}> {
    return this.queueReceivedOrderProduct[queueName].asObservable();
  }

  public async getDistinct(): Promise<any> {
    let data = {
      sizes: [],
      colors: [],
      productNames: [],
      sku: []
    };

    const existingData = this.cache.get('order-products-distinct');

    if (existingData) {
      return existingData;
    } else {
      try {
        const responses = await Promise.all([
          this.find({
            query: {
              $distinct: 'size'
            }
          }),
          this.find({
            query: {
              $distinct: 'color'
            }
          }),
          this.find({
            query: {
              $distinct: 'productName'
            }
          }),
          this.find({
            query: {
              $distinct: 'sku'
            }
          }),
          this.usersService.findCached()
        ]);

        data = {
          sizes: responses[0].data.filter((size) => size && size.name.length !== 0),
          colors: responses[1].data.filter((color) => color && color.name.length !== 0),
          productNames: responses[2].data.filter((productName) => productName && productName.name.length !== 0),
          sku: responses[3].data.filter((sku) => sku && sku.name.length !== 0)
        };

        this.cache.set('order-products-distinct', data, environment.cache.ttl.orderProductsDistinct);
      } catch (err) {
        this.toastService.error(err);
      }

      return data;
    }
  }

  public async getActionLog(id: number): Promise<any> {
    return await this.api.superGet('order-products/' + id + '/log');
  }

  public markWaitingForCustomer(id: number, reasonId: WaitingForCustomerReasonId): Promise<any> {
    return this.markOrClearWaitingForCustomer(id, reasonId);
  }

  public clearWaitingForCustomer(id: number): Promise<any> {
    return this.markOrClearWaitingForCustomer(id);
  }

  public async markOrClearWaitingForCustomer(
    id: number,
    reasonId: WaitingForCustomerReasonId = WaitingForCustomerReasonId.CLEAR
  ): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/mark-waiting-for-customer', { reasonId: reasonId });
  }

  public claimCrop(id: number): Promise<any> {
    return this.claimOrUnclaimCrop(id, true);
  }

  public unclaimCrop(id: number): Promise<any> {
    return this.claimOrUnclaimCrop(id, false);
  }

  private async claimOrUnclaimCrop(id: number, claim: boolean): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/claim-for-crop', {
      claim: claim
    });
  }

  public claimDesign(id: number): Promise<any> {
    return this.claimOrUnclaimDesign(id, true);
  }

  public unclaimDesign(id: number): Promise<any> {
    return this.claimOrUnclaimDesign(id, false);
  }

  private async claimOrUnclaimDesign(id: number, claim: boolean): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/claim-for-design', { claim: claim });
  }

  public async submitCrops(id: number, reSend: boolean = false, size = null): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/submit-crops', {
      reSend,
      size
    });
  }

  public async submitDesign(id: number, aiRatings: any): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/submit-design', {
      aiRatings: aiRatings
    });
  }

  public async assignCustomerService(id: number, userId: number): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/assign-customer-service', {
      customerServiceId: userId
    });
  }

  public claimDesignReview(id: number): Promise<any> {
    return this.claimOrUnclaimDesignReview(id, true);
  }

  public unclaimDesignReview(id: number): Promise<any> {
    return this.claimOrUnclaimDesignReview(id, false);
  }

  private async claimOrUnclaimDesignReview(id: number, claim: boolean): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/claim-for-review', { claim: claim });
  }

  public approveDesign(id: number, aiRatings: any): Promise<any> {
    return this.approveOrRejectDesign(id, true, null, null, aiRatings);
  }

  public rejectDesign(id: number, comment: string, croppedSubjectsToReject: number[]): Promise<any> {
    return this.approveOrRejectDesign(id, false, comment, croppedSubjectsToReject);
  }

  private async approveOrRejectDesign(
    id: number,
    approve: boolean,
    comment?: string,
    croppedSubjectsToReject?: number[],
    aiRatings?: any
  ): Promise<any> {
    const send: any = { approved: approve };

    if (approve && aiRatings) {
      send.aiRatings = aiRatings;
    }

    // Add comment if it's being rejected
    if (!approve) {
      send.comment = comment;
      send.disapprovedCroppedSubjects = croppedSubjectsToReject;
    }

    return await this.api.superPut('order-products/' + id + '/review-design', send);
  }

  public setMidTierOnly(id: number): Promise<any> {
    return this.setMidTierOnlyOrNot(id, true);
  }

  public setNotMidTierOnly(id: number): Promise<any> {
    return this.setMidTierOnlyOrNot(id, false);
  }

  public setInhouseOnly(id: number): Promise<any> {
    return this.setInhouseOnlyOrNot(id, true);
  }

  public setNotInhouseOnly(id: number): Promise<any> {
    return this.setInhouseOnlyOrNot(id, false);
  }

  public async setNoteOk(id: number): Promise<any> {
    this.api.superPut('order-products/' + id + '/note-ok');
  }

  private async setMidTierOnlyOrNot(id: number, midTierOnly: boolean): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/mid-tier-only', {
      midTierOnly: midTierOnly
    });
  }

  private async setInhouseOnlyOrNot(id: number, inhouseOnly: boolean): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/inhouse-only', {
      inhouseOnly: inhouseOnly
    });
  }

  public async markExpedited(id: number, expedited: boolean = true): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/mark-expedited', {
      expedited: expedited
    });
  }

  public async requiresSample(id: number, requiresSample: boolean = true): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/requires-sample', {
      requiresSample: requiresSample
    });
  }

  public async markReDesign(id: number, comment: string): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/mark-redesign', {
      comment: comment
    });
  }

  public async markRePrint(id: number, data: { reprints: number; comment: string }): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/mark-reprint', data);
  }

  public async approvePrints(id: number, data: { approve: number }): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/approve-print', data);
  }

  public async getPrintTemplate(
    id: number,
    updateStatus: boolean = false,
    download: boolean = true,
    locationId: number = null,
    alt: boolean = false
  ): Promise<any> {
    let url = 'order-products/' + id + '/print-template';

    if (updateStatus) {
      if (!locationId) {
        throw new Error('You have to select a location');
      }

      url += '?locationId=' + locationId;
    }

    const response = await this.api.superGet(url);

    if (response && download) {
      if (alt) {
        this.api.download(response.altUrl);
      } else {
        this.api.download(response.url);
      }
    }

    // Next, mark it as in production
    if (
      updateStatus &&
      response &&
      ![StatusId.IN_SAMPLE_PRODUCTION, StatusId.AWAITING_SAMPLE_APPROVAL, StatusId.SAMPLE_REJECTED].includes(
        response.orderProduct.statusId
      ) &&
      (response.orderProduct.statusId < StatusId.IN_PRODUCTION || response.orderProduct.statusId === StatusId.REPRINT)
    ) {
      // Should be OK to update OP and order with location since check has been done in above request
      await Promise.all([
        this.patch(
          id,
          {
            statusId:
              response.orderProduct.statusId === StatusId.AWAITING_SAMPLE_PRINT
                ? StatusId.IN_SAMPLE_PRODUCTION
                : StatusId.IN_PRODUCTION,
            locationId: locationId,
            newSocks: false
          },
          {
            query: {
              $skipAssociations: true
            }
          }
        ),
        this.ordersService.patch(
          response.orderProduct.orderId,
          {
            locationId: locationId
          },
          {
            query: {
              $skipAssociations: true
            }
          }
        )
      ]);
    }

    return response;
  }

  public async setPrintTemplate(id: number, data: any): Promise<any> {
    return await this.api.superPost('order-products/' + id + '/print-template', data);
  }

  public async cancel(id: number, cancel: boolean): Promise<any> {
    return await this.api.superPut('order-products/' + id + '/cancel', {
      cancel: cancel
    });
  }
}
