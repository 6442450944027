import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import feathers from '@feathersjs/client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private readonly feathers: any = feathers();
  private readonly socket: SocketIOClient.Socket;

  constructor() {
    this.socket = io(environment.apiUrl, {
      transports: ['websocket'],
      upgrade: false
    });
    this.feathers.configure(feathers.socketio(this.socket, { timeout: 30000 }));
    this.feathers.configure(
      feathers.authentication({
        storage: window.localStorage,
        timeout: 30000
      })
    );
  }

  public getSocket(): SocketIOClient.Socket {
    return this.socket;
  }

  /**
   * Get feathers service.
   * @param {string} name The service to get.
   * @returns {any} The feathers service.
   */
  public service(name: string): any {
    return this.feathers.service(name);
  }

  /**
   * Authenticate a user.
   * @param credentials The user credentials.
   * @returns {Promise<any>}
   */
  public authenticate(credentials?: any): Promise<any> {
    return this.feathers.authenticate(credentials);
  }

  /**
   * Logout a logged in user.
   */
  public logout(): Promise<any> {
    return this.feathers.logout();
  }
}
