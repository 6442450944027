<div class="app-container">
  <mat-toolbar *ngIf="!loggingOut && user" color="primary">
    <mat-toolbar-row [class.environment-warning]="environment.environmentWarning">
      <button
        mat-icon-button
        *ngIf="
          permissionsService.restrictFromRoles([
            RoleId.CROPPER,
            RoleId.OUTHOUSE_DESIGNER,
            RoleId.MID_TIER_DESIGNER,
            RoleId.OUTHOUSE_REVIEWER,
            RoleId.STICKER_DESIGNER,
            RoleId.SUBLIMATION_DESIGNER,
            RoleId.SUBLIMATION_REVIEWER,
            RoleId.STICKER_REVIEWER,
            RoleId.SHIPPINGEASY_UPLOADER
          ]) || permissionsService.isOuthouseManager()
        "
        (click)="sideNav && sideNav.toggle()"
        class="icon-padding"
      >
        <mat-icon aria-hidden="true">menu</mat-icon>
      </button>

      <img class="logo" alt="Logo" [src]="environment.logoLightUrl" />

      <span class="environment-indicator" *ngIf="environment.environmentWarning">{{
        environment.environmentWarning
      }}</span>

      <span fxFlex></span>

      <button class="screenshot-button" matTooltip="Report issue" mat-icon-button (click)="takeScreenshot()">
        <mat-spinner *ngIf="takingScreenshot" [diameter]="24" color="accent"></mat-spinner>
        <mat-icon *ngIf="!takingScreenshot">camera_alt</mat-icon>
      </button>

      <span class="user" *ngIf="user as user">Welcome, {{ user.firstName }} {{ user.lastName }}!</span>

      <button mat-stroked-button (click)="logout()">
        <mat-icon>beach_access</mat-icon>
        Logout
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container [class.has-toolbar]="user" [class.no-toolbar]="!user">
    <mat-sidenav
      *ngIf="!loggingOut && user"
      #sidenav
      class="mat-elevation-z2"
      [opened]="open"
      fixedTopGap="64"
      [mode]="mode"
    >
      <mat-nav-list>
        <app-sidebar *ngIf="user" #sidebar [user]="user"></app-sidebar>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content [class.is-navigating]="isNavigating">
      <div class="global-spinner navigation" *ngIf="isNavigating">
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>

      <div class="content">
        <router-outlet></router-outlet>
        <div *ngIf="!user" id="google_translate_element"></div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
