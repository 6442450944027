import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private keyPrefix: string = 'wntr_cache_';

  constructor() {}

  /**
   * Get cached data for a key.
   * @param key
   */
  public get(key: string): any {
    let data: any = localStorage.getItem(this.keyPrefix + key);

    if (data) {
      data = JSON.parse(data);

      if (data.expiry === 0 || data.expiry > new Date().getTime()) {
        return data.value;
      }
    }

    return null;
  }

  /**
   * Store data in the cache.
   * @param key Key that represents the value.
   * @param value The value to store.
   * @param ttl Number of seconds the cached value is available.
   */
  public set(key: string, value: any, ttl?: number): void {
    localStorage.setItem(
      this.keyPrefix + key,
      JSON.stringify({
        value: value,
        expiry: ttl > 0 ? new Date().getTime() + ttl * 1000 : 0
      })
    );
  }

  /**
   * Remove values added through this service or a single value.
   * @param key Optional key to remove value for.
   */
  public clear(key?: string): void {
    if (key) {
      localStorage.removeItem(this.keyPrefix + key);
    } else {
      const removeKeys = [];

      // Iterate over localStorage and insert the keys that meet the condition into arr
      for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).substring(0, this.keyPrefix.length) === this.keyPrefix) {
          removeKeys.push(localStorage.key(i));
        }
      }

      // Iterate over removeKeys and remove the items by key
      for (let i = 0; i < removeKeys.length; i++) {
        localStorage.removeItem(removeKeys[i]);
      }
    }
  }
}
