import { Injectable } from '@angular/core';
import feathers from '@feathersjs/client';
import { environment } from '../../environments/environment';
import * as superagent from 'superagent';
import { Router } from '@angular/router';
import { SocketService } from './socket.service';
import { Observable, Subject } from 'rxjs';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly feathers: any = feathers();
  private unauthorizedSubject: Subject<void> = new Subject();

  constructor(private router: Router, private socketService: SocketService) {
    this.feathers.configure(feathers.rest(environment.apiUrl).superagent(superagent));
    this.feathers.configure(
      feathers.authentication({
        storage: window.localStorage,
        timeout: 30000
      })
    );
  }

  /**
   * Get feathers object.
   */
  public getFeathers(): any {
    return this.feathers;
  }

  /**
   * Get feathers service.
   * @param {string} name The service to get.
   * @returns {any} The feathers service.
   */
  public service(name: string): any {
    return this.feathers.service(name);
  }

  /**
   * Get user's token.
   * @returns {Promise<any>}
   */
  public getToken(): Promise<any> {
    return this.feathers.passport.getJWT();
  }

  /**
   * Authenticate a user.
   * @param credentials The user credentials.
   * @returns {Promise<any>}
   */
  public authenticate(credentials?: any): Promise<any> {
    return this.feathers.authenticate(credentials);
  }

  /**
   * Fires unauthorized event when performing a request while unauthorized.
   */
  public onUnauthorized(): Observable<void> {
    return this.unauthorizedSubject.asObservable();
  }

  /**
   * Logout a logged in user.
   */
  public logout(): Promise<any> {
    return this.feathers.logout();
  }

  /**
   * Will 🐐 anything you want for you.
   * @param {string} endpoint
   * @param query
   * @returns {Promise<any>}
   */
  public async superGet(endpoint: string, query: any = {}): Promise<any> {
    const token = await this.getToken();

    try {
      const response = await superagent.get(this.getEndpointUrl(endpoint)).set('Authorization', token).query(query);

      return response.body;
    } catch (err) {
      if (err.response && err.response.body && err.response.body.name === 'NotAuthenticated') {
        this.unauthorizedSubject.next();
      } else {
        throw err;
      }
    }
  }

  /**
   * Will post anything for you.
   * @param {string} endpoint
   * @param data
   * @returns {Promise<any>}
   */
  public async superPost(endpoint: string, data: any = {}): Promise<any> {
    const token = await this.getToken();

    try {
      const response = await superagent.post(this.getEndpointUrl(endpoint)).set('Authorization', token).send(data);

      return response.body;
    } catch (err) {
      if (err.response && err.response.body && err.response.body.name === 'NotAuthenticated') {
        this.unauthorizedSubject.next();
      } else {
        throw err;
      }
    }
  }

  /**
   * Will put anything you want for you.
   * @param {string} endpoint
   * @param data
   * @returns {Promise<any>}
   */
  public async superPut(endpoint: string, data: any = {}): Promise<any> {
    const token = await this.getToken();

    try {
      const response = await superagent.put(this.getEndpointUrl(endpoint)).send(data).set('Authorization', token);

      return response.body;
    } catch (err) {
      if (err.response && err.response.body && err.response.body.name === 'NotAuthenticated') {
        this.unauthorizedSubject.next();
      } else {
        throw err;
      }
    }
  }

  public async uploadSignedImageS3(signData: any, file: any): Promise<any> {
    try {
      return await superagent
        .post(signData.url)
        .field('key', signData.fields.key)
        .field('acl', signData.fields.acl)
        .field('bucket', signData.fields.bucket)
        .field('X-Amz-Algorithm', signData.fields['X-Amz-Algorithm'])
        .field('X-Amz-Credential', signData.fields['X-Amz-Credential'])
        .field('X-Amz-Date', signData.fields['X-Amz-Date'])
        .field('X-Amz-Signature', signData.fields['X-Amz-Signature'])
        .field('Policy', signData.fields['Policy'])
        .attach('file', file);
    } catch (err) {
      throw err;
    }
  }

  /**
   * Will download anything for you.
   * @param {string} url
   */
  public download(url: string, filename?: string, target?: string) {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');

    if (filename) {
      a.setAttribute('download', filename);
    }

    if (target) {
      a.setAttribute('target', target);
    }

    document.body.appendChild(a);
    a.href = url;

    try {
      a.click();
    } catch (err) {
      if (err.response.body.name === 'NotAuthenticated') {
        this.unauthorizedSubject.next();
      } else {
        throw err;
      }
    }
  }

  /**
   * Return full URL to the given endpoint
   */
  public getEndpointUrl(endpoint: string): string {
    return environment.apiUrl + '/' + endpoint;
  }
}
