import { Component, NgZone, ViewChild, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { User } from './models/user.model';
import { PermissionsService } from './services/permissions.service';
import { RoleId } from './models/role.model';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SocketService } from './services/socket.service';
import { MatSidenav } from '@angular/material/sidenav';
import { TransitionLogItem } from './models/transition-log.model';
import { TransitionLogService } from './services/transition-log.service';
import { StatusId } from './models/status.model';
import { ToastService } from './services/toast.service';
import { Subject } from 'rxjs';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from '../environments/environment';
import { takeUntil } from 'rxjs/operators';
import html2canvas from 'html2canvas';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReportingDialogComponent } from './components/dialogs/reporting-dialog/reporting-dialog.component';
import { OrderActionLogService } from './services/order-action-log.service';
import { OrderProductsService } from './services/order-products.service';
import { OrdersService } from './services/orders.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public environment: any = environment;
  public RoleId: any = RoleId;
  private unsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('sidenav', { static: false }) public sideNav: MatSidenav;
  public user: User;
  public mode: string;
  public open: string;
  public width: number;
  public height: number;
  public isNavigating: boolean = false;
  public loggingOut: boolean = false;
  public takingScreenshot: boolean = false;
  public hasMultipleRoles: boolean = false;

  constructor(
    private router: Router,
    private socketService: SocketService,
    private ngZone: NgZone,
    private toastService: ToastService,
    private transitionLogService: TransitionLogService,
    public permissionsService: PermissionsService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    public dialog: MatDialog,
    private orderActionLogService: OrderActionLogService,
    private orderProductsService: OrderProductsService,
    private ordersService: OrdersService,
    private readonly elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.angulartics2GoogleAnalytics.startTracking();
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        this.isNavigating = true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.isNavigating = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async ngOnInit(): Promise<void> {
    this.initSocketEndpoints();

    try {
      await this.socketService.authenticate();
    } catch (err) {}

    window.onresize = (e: Event) => {
      this.ngZone.run(() => {
        this.changeMode();
      });
    };

    const loadScript = this.renderer.createElement('script');
    loadScript.text =
      "function googleTranslateElementInit() {new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');}";
    this.renderer.appendChild(this.elementRef.nativeElement, loadScript);

    const script = this.renderer.createElement('script');
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

  public initSocketEndpoints(): void {
    this.permissionsService
      .onUser()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user;
        const minRole = user && Math.min(...user.roleIds);
        this.hasMultipleRoles =
          minRole < RoleId.CROPPER ||
          user.roleIds.includes(RoleId.ACCOUNT_REP) ||
          (this.permissionsService.restrictToRoles([
            RoleId.CROPPER,
            RoleId.OUTHOUSE_DESIGNER,
            RoleId.MID_TIER_DESIGNER,
            RoleId.OUTHOUSE_REVIEWER,
            RoleId.STICKER_DESIGNER,
            RoleId.LABELER,
            RoleId.SUBLIMATION_DESIGNER,
            RoleId.SUBLIMATION_REVIEWER,
            RoleId.STICKER_REVIEWER
          ]) &&
            user.roleIds.length > 1);
        this.changeMode();
      });

    this.transitionLogService
      .onTransitionLogItemCreated()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: TransitionLogItem) => {
        if (
          item &&
          this.permissionsService.restrictToRoles([RoleId.SHIPPING_MANAGER], false) &&
          item.toStatus.id === StatusId.SHIPPED &&
          item.fromStatus.id !== StatusId.AWAITING_SHIPMENT
        ) {
          const message =
            'Order product ' +
            item.orderProduct.number +
            ' was marked shipped but its previous status was `' +
            item.fromStatus.name +
            '`';
          this.toastService.error(message, null, {
            disableTimeOut: true,
            closeButton: true,
            tapToDismiss: false,
            positionClass: 'toast-bottom-center',
            enableHtml: true
          });
        }
      });
  }

  public changeMode(): void {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    if (this.width <= 800) {
      this.mode = 'over';
      this.open = 'false';
    }

    if (this.width > 800) {
      this.mode = 'side';

      if (
        this.user &&
        (this.hasMultipleRoles ||
          this.permissionsService.restrictToRoles([RoleId.ORDER_ASSISTANT]) ||
          this.permissionsService.restrictToRoles([RoleId.RETAIL_MANAGER]) ||
          this.permissionsService.restrictToRoles([RoleId.BULK_DESIGNER]) ||
          this.permissionsService.isOuthouseManager())
      ) {
        this.open = 'true';
      }
    }
  }

  public async logout(): Promise<void> {
    try {
      this.loggingOut = true;
      await this.permissionsService.logout();
    } finally {
      this.loggingOut = false;
    }
  }

  public async takeScreenshot(): Promise<void> {
    this.takingScreenshot = true;

    try {
      const canvas = await html2canvas(document.body);

      let dataDump: any = {},
        matches: string[];

      // If we're viewing a single order or order product, grab the details and the action log
      if ((matches = this.router.url.match(/.*\/orders\/(\d*)$/))) {
        dataDump.actionLog = await this.orderActionLogService.find({
          query: {
            orderId: matches[1],
            $limit: -1,
            $sort: {
              createdAt: -1
            }
          }
        });
        dataDump.order = await this.ordersService.get(matches[1]);
      } else if ((matches = this.router.url.match(/.*\/order-products\/(\d*)$/))) {
        dataDump.actionLog = (await this.orderProductsService.getActionLog(parseInt(matches[1]))).data;
        dataDump.orderProduct = await this.orderProductsService.get(parseInt(matches[1]));
      }

      this.dialog.open(ReportingDialogComponent, {
        width: '800px',
        data: {
          image: canvas.toDataURL(),
          time: new Date(),
          dataDump
        }
      });
    } catch (err) {
      this.toastService.error('Failed to take screenshot!', null);

      throw err;
    } finally {
      this.takingScreenshot = false;
    }
  }
}
