import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private service: any;

  constructor(private api: ApiService, private cache: CacheService) {
    this.service = this.api.service('settings');
  }

  public async get(name: string, skipCache = false, onlyValue = true): Promise<any> {
    if (!skipCache) {
      const cached = this.cache.get('setting_' + name);

      if (cached) {
        return cached;
      }
    }

    try {
      let result = await this.service.find({});

      if (!result || result.length === 0) {
        return null;
      }

      result = result.filter((s) => s.name === name);

      this.cache.set('setting_' + name, result[0].value, environment.cache.ttl.settings);

      return onlyValue ? result[0].value : result[0];
    } catch (err) {
      return null;
    }
  }

  public createScatterPattern(key: string, name: string): Promise<any> {
    return this.service.create({
      key: key,
      name: name,
      isScatterPattern: true
    });
  }

  public find(params?: any): Promise<any> {
    return this.service.find(params);
  }

  public patch(id: number, data: any): Promise<any> {
    return this.service.patch(id, data);
  }
}
